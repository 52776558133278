import { COUNTRIES_WITHOUT_STATE, ShippingAddressCountry } from '@remento/types/shipping';
import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';

export function createBookShippingFormSchema() {
  return z
    .object({
      recipientName: z.string().min(1, { message: 'This is a required field' }),
      addressLine1: z.string().min(1, { message: 'This is a required field' }),
      addressLine2: z.string().nullable(),
      city: z.string().min(1, { message: 'This is a required field' }),
      state: z.string(),
      zipCode: z.string().min(1, { message: 'This is a required field' }),
      country: z.nativeEnum(ShippingAddressCountry),
    })
    .refine(
      (data) => {
        return COUNTRIES_WITHOUT_STATE.includes(data.country) || (data.state && data.state.length > 0);
      },
      {
        message: 'This is a required field',
        path: ['state'],
      },
    );
}

export type BookShippingFormInput = z.input<ReturnType<typeof createBookShippingFormSchema>>;
export type BookShippingFormOutput = z.output<ReturnType<typeof createBookShippingFormSchema>>;
export type BookShippingForm = Form<BookShippingFormInput, BookShippingFormOutput>;

export function createBookShippingForm(defaultValues?: DeepPartial<BookShippingFormInput>): BookShippingForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createBookShippingFormSchema(),
  });
}
