import { COUNTRIES_WITHOUT_STATE, ShippingAddressCountry, ShippingAddressCountryName } from '@remento/types/shipping';

import { RMInput } from '@/components/RMInput/RMInput';
import { RMSelect } from '@/components/RMSelect';
import { RMText } from '@/components/RMText/RMText';
import { InputContainer, InputController } from '@/modules/form/container';
import { useInputValue } from '@/modules/form/input';

import { BookShippingForm } from '../../book-shipping.form';

import { StyledBookOrderFormView } from './BookShippingFormView.styles';

const COUNTRY_OPTIONS = [
  {
    label: '',
    value: '',
    disabled: true,
  },
  ...Object.entries(ShippingAddressCountryName).map(([value, label]) => ({ value, label })),
];

export interface BookShippingFormViewProps {
  form: BookShippingForm;
}

export function BookShippingFormView({ form }: BookShippingFormViewProps) {
  const country = useInputValue(form, 'country');

  return (
    <StyledBookOrderFormView>
      <InputContainer form={form} path="recipientName">
        {(props) => (
          <RMInput
            id="book-order-shipping-address-recipient-name"
            label="Recipient name*"
            placeholder="First Last"
            {...props}
          />
        )}
      </InputContainer>
      <InputContainer form={form} path="addressLine1">
        {(props) => (
          <RMInput
            id="book-order-shipping-address-line1"
            label="Address Line 1*"
            placeholder="65 Hansen Way"
            {...props}
          />
        )}
      </InputContainer>
      <InputContainer form={form} path="addressLine2">
        {(props) => (
          <RMInput id="book-order-shipping-address-line2" label="Address Line 2" placeholder="Apartment 4" {...props} />
        )}
      </InputContainer>
      <InputContainer form={form} path="city">
        {(props) => (
          <RMInput id="book-order-shipping-address-city" label="City/Town*" placeholder="Los Angeles" {...props} />
        )}
      </InputContainer>
      <InputContainer form={form} path="state">
        {(props) => (
          <RMInput
            id="book-order-shipping-address-state"
            label={`State/Region/Province${country !== null && COUNTRIES_WITHOUT_STATE.includes(country) ? '' : '*'}`}
            placeholder="California"
            {...props}
          />
        )}
      </InputContainer>
      <InputContainer form={form} path="zipCode">
        {(props) => (
          <RMInput id="book-order-shipping-address-zip" label="Zip/Post code*" placeholder="93034" {...props} />
        )}
      </InputContainer>
      <InputController form={form} path="country">
        {({ value, error, onBlur, onChange }) => (
          <RMSelect
            id="book-order-shipping-address-country"
            label="Country*"
            options={COUNTRY_OPTIONS}
            value={value ?? ''}
            error={error}
            onChange={(value) => {
              onChange(value as ShippingAddressCountry);
              onBlur();
            }}
          />
        )}
      </InputController>
      <RMText type="sans" size="s" color="on-surface-primary">
        Orders within US ship via economy shipping. Please allow up to 3 weeks for printing & shipping. For multiple
        addresses, please place separate orders. International orders will incur an additional shipping fee.
      </RMText>
    </StyledBookOrderFormView>
  );
}
